<template>
	
	<div class="Finance">
		
		<div class="Act">
			
			<div class="Item">
				
				<div class="Left">
					<i class="el-icon-wallet"></i>
				</div>
				<div class="Right">
					<label>可用余额</label>
					￥{{FinanceAccount.Blance}}
				</div>
				
			</div>
			
			<div class="Item">
				
				<div class="Left">
					<i class="el-icon-lock"></i>
				</div>
				<div class="Right">
					<label>冻结额度</label>
					￥{{FinanceAccount.BlanceFrozen}}
				</div>
				
			</div>
			
			<div class="Item">
				
				<div class="Left">
					<i class="el-icon-bank-card"></i>
				</div>
				<div class="Right">
					<label>提现账号</label>
					<em><el-button size="mini" type="primary" @click="$Jump('/my/finance/withdraw_account_list')">点此查看/设置</el-button></em>
				</div>
				
			</div>
			
			<div class="Item">
				
				<div class="Left">
					<i class="el-icon-money"></i>
				</div>
				<div class="Right">
					<label>提现</label>
					<em><el-button @click="dialogVisible = true" size="mini" type="primary">我要提现</el-button></em>
				</div>
				
			</div>
			
		</div>
		
		
		<div class="DetailList">
			
			<h3>财务记录</h3>
			
			<li v-if="FinanceTradeDetailList.length == 0" style="color: rgba(0,0,0,0.3);">
				暂无
			</li>
			
			<li v-for="(item,itemI) in FinanceTradeDetailList" :key="itemI">
				<span style="display: inline-block;width: 130px;">
					{{item.TypeName}}
				</span>
				<em style="margin-left: 20px;">
					
					<template v-if="item.Amount > 0">
						￥{{item.Amount}}
					</template>
					<template v-else>
						<i>￥{{item.Amount}}</i>
					</template>
				</em>
				<span>
					{{item.CreatedAt}}
				</span>
			</li>
			
			
			<div class="Page" v-if="Total >= PageSize">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
			
		</div>
		
		<el-dialog
		  title="提现"
		  :visible.sync="dialogVisible"
		  width="400px"
		  :before-close="handleClose">
		  <span style="display: block;">可用余额￥{{FinanceAccount.Blance}}元</span>
		  <span  style="display:bolck;padding-top:10px;color: rgba(0,0,0,0.3);">冻结金额为￥{{FinanceAccount.BlanceFrozen}}元</span>
		  <li style="margin-top: 10px;">
			  <el-input-number style="width: 150px;" v-model="WithdrawMoney" placeholder="请输入提现金额" :step="0.01" :precision="2" :controls="false" :max="FinanceAccount.Blance"></el-input-number>
			  <span style="margin-left: 10px;color: rgba(0,0,0,0.3);">提现金额，单位元</span>
		  </li>
		  
		  <li style="margin-top: 10px;margin-bottom: 10px;">
			  <el-select style="width: 340px;" v-model="FinanceWithdrawAccountId" placeholder="选择收款账号">
				  <el-option :label="item.TypeName + item.BankName + ' - ' + item.No+'('+item.Holder+')'" :value="item.Id" v-for="(item,itemI) in FinanceWithdrawAccountList" :key="itemI"></el-option>
			  </el-select>
		  </li>
		  
		  <span>
			  <el-button type="primary" @click="Withdraw()">确 定</el-button>
		    <el-button @click="dialogVisible = false">取 消</el-button>
		  </span>
		</el-dialog>
	

	</div>
</template>

<script>
	import {Pagination,Dialog,InputNumber,Select,Option} from 'element-ui'
	export default {
	  name: 'FinanceIndex',
	  props: {
	  },
	  data() {
	      return {
			  FinanceAccount:{
				  Blance:0.00,
				  BlanceFrozen:0.00,
				  WithdrawCharge:0.01
			  },
			  WithdrawMoney:0.00,
			  dialogVisible:false,
			  FinanceTradeDetailList:[],
			  FinanceWithdrawAccountList:[],//提现账号列表
			  FinanceWithdrawAccountId:'',//提现账号
			  Page:1,
			  PageSize:8,
			  Total:0
	      }
	  },
	  components: {
		'el-pagination':Pagination,
		'el-dialog':Dialog,
		'el-input-number':InputNumber,
		'el-select':Select,
		'el-option':Option
	  },
	  created() {
	  	this.GetFinanceAccount()
		this.GetFinanceTradeDetailList(this.Page)
		this.GetFinanceWithdrawAccountList()
	  },
	  methods:{
		  handleClose(){
			  this.dialogVisible = false
		  },
		  GetFinanceAccount(){
		  			  let data = {
		  			  	Service:'Finance',
		  			  	Class: 'FinanceAccount',
		  			  	Action: 'Get',
		  				
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  			  
		  			  	this.FinanceAccount = res.Data
						
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetFinanceWithdrawAccountList(){ //获取提现账号清单
			  let data = {
			  	Service:'Finance',
			  	Class: 'FinanceWithdrawAccount',
			  	Action: 'List',
				Page:1,
				PageSize:20,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
			  	
			  	if(res.ErrorId != 0){
			  		this.$message(res.Msg)
			  		return
			  	}
			  						
					if(res.Data.FinanceWithdrawAccountList == undefined || res.Data.FinanceWithdrawAccountList == null){
						this.FinanceWithdrawAccountList = []
						return
					}
			  				
						
									
			  	this.FinanceWithdrawAccountList = res.Data.FinanceWithdrawAccountList
				
				if(this.FinanceWithdrawAccountList.length == 0){
					return
				}
				this.FinanceWithdrawAccountId = this.FinanceWithdrawAccountList[0].Id
			  		  						
			  })
			  .catch(function (response) {
			  	this.$message('网络请求错误')
			  })
		  },
		  GetFinanceTradeDetailList(_page){
		  			  let data = {
		  			  	Service:'Finance',
		  			  	Class: 'FinanceTradeDetail',
		  			  	Action: 'List',
		  				Page:_page,
						PageSize:this.PageSize,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
						
						if(res.Data.TradeDetailList == undefined || res.Data.TradeDetailList == null){
							this.FinanceTradeDetailList = []
							return
						}
						
		  			  	this.FinanceTradeDetailList = res.Data.TradeDetailList
					
		  						
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  Withdraw(){ //提现
		  			  let data = {
		  			  	Service:'Finance',
		  			  	Class: 'FinanceWithdraw',
		  			  	Action: 'Add',
						WithdrawAmount:this.WithdrawMoney,
						WithdrawRemark:'PC端C端自主提现',
						WithdrawAccountId:this.FinanceWithdrawAccountId
		  			  }
					  
					  if(this.FinanceWithdrawAccountList.length == 0){
						  this.$message('缺少提现账号')
						  return
					  }
					  if(this.FinanceWithdrawAccountId.length == 0){
						  this.$message('请选择提现账号')
						  return
					  }
					  
					  if(parseFloat(data.WithdrawAmount) <= 0){
						  this.$message('提现金额必须为大于0的数字')
						  return
					  }
					  
					  
					  
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  				
						this.dialogVisible = false
		  				this.GetFinanceAccount()
						this.GetFinanceTradeDetailList(1)
		  				
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
	  }
	}
</script>

<style scoped>
.Finance{
	margin: 20px 0px 20px 20px;
}
.Finance .Act{
	display: flex;
	justify-content: space-between;
}
.Finance .Act .Item{
	flex: 1;
	display: flex;
	align-items: center;
	background-color: #FFFFFF;
	margin-right: 20px;
	padding: 20px 0px;
}
.Finance .Act .Item .Left{
	padding: 0px 20px;
	font-size: 2rem;
	color: rgba(56,203,28,1);
}
.Finance .Act .Item .Right{
	flex:1;
	font-size: 26px;
}
.Finance .Act .Item .Right label{
	display: block;
	color: rgba(0,0,0,0.3);
	font-size: 14px;
}

.Finance .DetailList{
	margin-top: 20px;
	margin-right: 20px;
	background-color: #FFFFFF;
	padding: 20px;
}
.Finance .DetailList h3{
	font-weight: normal;
	color: rgba(0,0,0,0.4);
}
.Finance .DetailList li{
	display: flex;
	padding: 10px 0px;
	border-bottom: 1px solid rgba(0,0,0,0.05);
}
.Finance .DetailList li em,.Finance .DetailList li i{
	flex: 1;
	font-style: normal;
	font-size: 1.2rem;
	align-items: center;
}
.Finance .DetailList li em{
	color: rgba(63,224,63,1);
}
.Finance .DetailList li i{
	color: crimson;
}
.Finance .DetailList li span{
	color: rgba(0,0,0,0.3);
}
</style>
